/* ==========================================================================
   #LISTS
   ========================================================================== */

.list-default,
ul,
ol {
    margin: 0 rhythm(1) rhythm(1);

    > li {
        margin-bottom: rhythm(0.15);
    }
}

.list-inline {
    list-style: none;

    > li {
        display: inline;
    }
}

    .list-inline--middle {
        > li {
            vertical-align: middle;
        }
    }


.list-plain {
    list-style: none;
    margin: 0;
}

.list-two-col {
    @include clearfix;
    list-style: none;
    margin: 0;

    > li {
        width: 47%;
        margin-right: 3%;
        float:left;
        display:inline;

        @include grid-media-query("tablet") {
            width: 100%;
            margin-right: 0;
        }

        img {
            margin-right: rhythm(.5);
        }
    }
}

.list-item-icon {
    padding-left: rhythm(1.8);
    padding-bottom: rhythm(.5);
    min-height: 50px;
}