/* ==========================================================================
   #HEADER
   ========================================================================== */

.site-head {
    position: relative;

    //background: #FFF;

    padding: rhythm(1) 0;

    z-index: 10;

    transition: background .1s;

    @include grid-media-query("tablet-down") {
        padding: rhythm(1) 0;
        position: fixed;
        top: 0;
        left: 0; right: 0;
        background: #fff;
    }
}

    .site-head--open {
        background: #fff;
        transition: background .1s;
    }

.nav-primary {
    @include clearfix;
}

    .nav-primary__menu {
        float: right;
        clear: right;
        margin: 0;

        > li {
            margin-left: rhythm( 1.5 );

            > a {
                @include typecsset-font-size( 19px);
                font-weight: 700;

                color: $brand-primary;

                &:hover,
                &:focus,
                &.active {
                    color: $brand-quaternary;
                }
            }

            &.active a {
                color: $brand-quaternary;
            }
        }

        @include grid-media-query("tablet-down") {
            display: none;
            float: none;
            clear: both;

            text-align: center;

            > li {
                display: block;
                text-align: center;
                margin: rhythm(0.5) 0;

                > a {
                    margin: rhythm( 0.5 ) 0;
                    @include typecsset-font-size( 20px);

                    &:hover {
                        color: #FFF;
                    }
                }
            }

            &.nav-primary__menu--open {
                display: block;
            }
        }
    }

    .nav-primary__menu--contact {
        margin-bottom: rhythm(0.5);
        margin-top: rhythm(0.25) * -1;

        li {
            margin-left: 0;
            margin-right:  rhythm( 1 );

            > a {
                color: $brand-tertiary;

                &[href^=tel]{
                    color: $brand-secondary;
                }

                i.fa {
                    margin-right: rhythm(0.3);
                }
            }

            &.link-flag {
                margin-right: 2px;
                line-height: 1;
                padding: 0;

                .flag-icon {
                    width: 24px;
                    height: 18px;
                    line-height: 0;
                    vertical-align: middle;

                    &.flag-icon-ru {
                        position: relative;
                        &:after {
                            content: '';
                            position: absolute;
                            top: 0; bottom: 0;
                            right: 0; left: 0;
                            background: rgba(0,0,0,0.05);
                        }
                    }
                }

                @include grid-media-query("tablet-down") {
                    display: inline-block;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }

        @include grid-media-query("tablet-down") {
            padding-top: rhythm(1);
        }
    }

    .nav-primary__logo {
        @include hidetext;
        float: left;

        background: url('../images/logo.svg') no-repeat center bottom;
        background-size: 100% auto;
        width: 340px;
        height: 60px;

        @include grid-media-query("tablet") {
            width: 240px;
            height: 45px;
        }

        @include grid-media-query("mobile") {
           width: 160px;
           height: 30px;
        }
    }

    .nav-primary__toggle {
        float: right;
        margin-top: rhythm(0.5);

        display: none;


        @include grid-media-query("tablet-down") {
            display: block;
             margin-top: rhythm(0.3);
        }
        @include grid-media-query("mobile") {
            margin-top: rhythm(0.15);
        }
    }
