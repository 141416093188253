/* ==========================================================================
   #ICONS
   ========================================================================== */


.icon-bus {
    background: url('../images/icon-bus.png') no-repeat 0% 15%;
}

.icon-rail {
    background: url('../images/icon-rail.png') no-repeat 0% 15%;
}

.icon-tube {
    background: url('../images/icon-tube.png') no-repeat 0% 15%;
}