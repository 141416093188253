/* ==========================================================================
   #FORMS
   ========================================================================== */

.form,
.cpp_form {
    margin-bottom: rhythm(1);

    h1 {
        display: none;
    }
}


    .form__fieldset {
        padding: rhythm(1);

        border: 1px solid $border-color;
        border-radius: $border-radius;
    }


    .form__row,
    .cpp_form .fields {
        margin: 0;
        padding: 0 !important;
        margin-bottom: rhythm(0.25);

        label {
            display: none;
        }
    }


    .form__label {
        display: block;
        margin-bottom: rhythm(0.4)
    }


    .form__input,
    .cpp_form .field {
        padding: rhythm(0.5) rhythm(1) !important;

        border: 1px solid #FFF;

        width: 100% !important;

        &:focus {
            border: 1px solid $brand-primary;
        }

        &.error {
            border: 1px solid darken(red, 10%);
        }
    }

    .form__input-small {
        width: rhythm(4);
        margin-right: rhythm(0.5);
        text-align: center;
    }

    .form__text-area {
        min-height: 100px;
    }

    .form__input-select {
        width: auto;
        min-width: 200px;
    }

    .form__input-checkbox {
        margin-left: rhythm(0.2);
        margin-right: rhythm(0.8);
    }


    .cpp_form  .pbSubmit {
        float: none;
        margin: 0;
        @extend .btn;
        @extend .btn--primary;
    }

    .cpp_form .message {
        color: $brand-primary;
        padding: rhythm(.2) rhythm(1);
    }