/* ==========================================================================
   #VARIABLES
   ========================================================================== */


/* Base Units
   ========================================================================== */

$base-font-size: 17px;
$base-line-height: 23px;

$base-font-family: 'Oxygen', sans-serif;
$base-font-family-alt: $base-font-family;

$base-gutter: 30px;
$border-radius: 3px;


/* Library Overrides
   ========================================================================== */

$gutter: 30px; // csswizardry grids
$use-silent-classes: false; // csswizardry grids

$typecsset-base-font-size: $base-font-size; // typecsset
$typecsset-base-line-height: $base-line-height; // typecsset

$slick-font-path: "";
$slick-font-family: "Arial";
$slick-loader-path: "";

$fa-font-path: "../fonts/";

$flag-icon-css-path: '../images/flags';


/* Break points
   ========================================================================== */

$mobile-min: 320px;
$mobile-max: 600px;
$tablet-min: $mobile-max + 1;
$tablet-max: 990px;
$desk-min: $tablet-max + 1;
$page-max: 1080px;

$breakpoints: (
    'mobile' '(max-width: #{$mobile-max})',
    'tablet-down' '(max-width: #{$tablet-max})',
    'tablet' '(min-width: #{$tablet-min}) and (max-width: #{$tablet-max})',
    'tablet-up' '(min-width: #{$tablet-min})',
    'desk' '(min-width: #{$desk-min})'
);

$breakpoint-has-widths: ('mobile', 'tablet', 'tablet-down', 'tablet-up', 'desk');
$breakpoint-has-push:   ('mobile', 'tablet', 'tablet-down', 'desk');
$breakpoint-has-pull:   ('mobile', 'tablet', 'tablet-down', 'desk');


/* Theme
   ========================================================================== */

$brand-primary:       #2b3990;
$brand-secondary:     #ef557b;
$brand-tertiary:      #00a79d;
$brand-quaternary:    #00adee;
$brand-quinary:       #8b6f94;

$bg-default:          #FFF;
$bg-alt:              #e1e1e1;

$border-color:        $brand-tertiary;

$text-color:          #8b6f94;
$text-invert:         #FFF;

$font-size-sml:       16px;
$font-size-x-sml:     14px;
