/* ==========================================================================
   #SLIDER
   ========================================================================== */

.slider {
}

    .slider__slide {
        position: relative;
        width: 100%;

        > img {
            width: 100%;
        }
    }


.slick-dots {
    text-align: center;

    > li {
        display: inline-block;
        margin: 0 rhythm(0.25);

        > button {
            @include hidetext;

            width: rhythm(0.5);
            height: rhythm(0.5);

            background: $brand-tertiary;
            border: none;
            border-radius: 80%;

            cursor: pointer;
        }

        &.slick-active > button {
            background: $brand-primary;
        }
    }

    @include grid-media-query("mobile") {
        margin-top: rhythm(0.5);
    }
}