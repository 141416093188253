/* ==========================================================================
   #LIGHTBOX
   ========================================================================== */

.lightbox-wrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background: #000;
    background: rgba(0,0,0,0.8);

    z-index: 1010;
}


.lightbox {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 630px;
    height: auto;

    transform: translate(-50%, -50%);

    padding: 0;

    //background: $brand-secondary;


    @include grid-media-query("mobile") {
        width: 95%;
    }
}

    .lightbox__body {
        padding: 25px;

        background: #FFF;

        p:last-child {
            margin-bottom: 0;
        }

        h2 {
            font-weight: 400;
            line-height: 1.4;
            margin: 0;

            strong {
                font-weight: 600;
            }
        }
    }


    .lightbox__map-wrap {
        position: relative;
        width: 100%;
        height: 260px;

        @include grid-media-query("mobile") {
            display: none;
        }
    }

    .lightbox__close {
        position: absolute;
        top: 10px;
        right: 15px;

        line-height: 1;

        font-size: 30px;
    }

    .lightbox__logo {
        float: none;

        margin: rhythm(.5) auto;

    }