/* ==========================================================================
   #TEXT HELPERS
   ========================================================================== */

/*
 * Alignment
 */

.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.text-left {
    text-align: left;
}


/*
 * Size
 */

.text-small {
    @include typecsset-font-size( $font-size-sml );
}
.text-x-small {
    @include typecsset-font-size( $font-size-x-sml );
}
.text-large {
    @include typecsset-font-size( 23px );
}


/*
 * Color
 */

.text-primary {
    color: $brand-primary !important;
}
.text-secondary {
    color: $brand-secondary !important;
}
.text-tertiary {
    color: $brand-tertiary !important;
}
.text-quaternary {
    color: $brand-quaternary !important;
}
.text-quinary {
    color: $brand-quinary !important;
}
.text-inverted {
    color: $text-invert !important;
}


/*
 * Transform
 */

.text-uppercase {
    text-transform: uppercase;
}
