/* ==========================================================================
#THEME
========================================================================== */


/*
 * Borders
 */




/*
 * Backgrounds
 */

.bg-primary {
    background: $brand-primary;
}
.bg-secondary {
    background: $brand-secondary;
}
.bg-tertiary {
    background: $brand-tertiary;
}
.bg-quaternary {
    background: $brand-quaternary;
}
.bg-quinary {
    background: $brand-quinary;
}