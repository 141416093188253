/*------------------------------------*\
    #GUTTERS
\*------------------------------------*/

/**
 * Gutter Resets
 */

.tight {
    margin: 0 !important;
}

@include grid-media-query("mobile") {
    .tight--mobile {
        margin: 0 !important;
    }
}

@include grid-media-query("tablet") {
    .tight--tablet {
        margin: 0 !important;
    }
}

@include grid-media-query("desk") {
    .tight--desk {
        margin: 0 !important;
    }
}

.full {
    padding: 0 !important;
}

@include grid-media-query("mobile") {
    .full--mobile {
        padding: 0 !important;
    }
}

@include grid-media-query("tablet") {
    .full--tablet {
        padding: 0 !important;
    }
}

@include grid-media-query("desk") {
    .full--desk {
        padding: 0 !important;
    }
}


/**
 * Vertical Gutters
 */

@for $i from 1 through 10 {

    .vgut-m-#{$i} {
        margin-top: $base-gutter * $i;
        margin-bottom: $base-gutter * $i;
    }

    .vgut-m-t-#{$i} {
        margin-top: $base-gutter * $i;
    }

    .vgut-m-b-#{$i} {
        margin-bottom: $base-gutter * $i;
    }

    .vgut-p-#{$i} {
        padding-top: $base-gutter * $i;
        padding-bottom: $base-gutter * $i;
    }

    .vgut-p-t-#{$i} {
        padding-top: $base-gutter * $i;
    }

    .vgut-p-b-#{$i} {
        padding-bottom: $base-gutter * $i;
    }

}


/**
 * Horizontal gutters
 */

@for $i from 1 through 10 {

    .gut-m-#{$i} {
        margin-right: $base-gutter * $i;
        margin-left: $base-gutter * $i;
    }

    .gut-m-l-#{$i} {
        margin-left: $base-gutter * $i;
    }

    .gut-m-r-#{$i} {
        margin-right: $base-gutter * $i;
    }

    .gut-p-#{$i} {
        padding-right: $base-gutter * $i;
        padding-left: $base-gutter * $i;
    }

    .gut-p-l-#{$i} {
        padding-left: $base-gutter * $i;
    }

    .gut-p-r-#{$i} {
        padding-right: $base-gutter * $i;
    }
}