/* ==========================================================================
   #PAGES
   ========================================================================== */

.page-section {
    position: relative;
}


.section-image {
    vertical-align: bottom;
    line-height: 1px;

    > img {
        min-width: 100%;
        line-height: 1px;
        vertical-align: bottom;
    }
}

    .section-image-bg {
        height: 350px;

        background-size: cover;
        background-position: right;

        @include grid-media-query("mobile") {
            height: 300px;
        }
    }

.section-map {
    vertical-align: bottom;
    min-height: 350px;
}
    .section-map--full {
        height: 500px;

        @include grid-media-query("mobile") {
            height: 350px;
        }
    }

/*
 * Standard Map
*/
.map {
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
    width: 100%;
    height: 100%;

    @include grid-media-query("mobile") {
        height: 350px;
        position: relative;
    }
}





/*
 * 50/50 Split
 * Text and Image
 */

.section-split {
    position: relative;
    overflow: hidden;

    z-index: 0;
}

    .section-split__text {
        padding: rhythm(1) 0;


        @include grid-media-query("tablet-up") {

            padding-right: rhythm(1);

            .section-split--image-left & {
                margin-left: 50%;
                padding-left: rhythm(1);
                padding-right: 0;
            }
        }
    }

    .section-split__image {
        position: relative;
        margin-bottom: rhythm(1);

        @include grid-media-query("tablet-up") {
            margin-bottom: 0;
            display: block;

            position: absolute;
            top: 0; bottom: 0;
            left: 50%;

            width: 50%;
            height: 100%;
            overflow: hidden;

            .section-split--image-left & {
                left: auto;
                right: 50%;
            }

            > img,
            > iframe {
                display: block;

                position: absolute;
                top: 50%;
                left: 50%;

                transform: translate(-50%, -50%);

                width: auto !important;
                height: auto;

                max-width: none;
                max-height: none;

                min-width: 100.5%;
                min-height: 100.5%;

                vertical-align: bottom;
            }
        }
    }

@include grid-media-query("tablet-up") {

    .section-split--bg {
        &:after {
            content: '';
            position: absolute;
            top: 0; bottom: 0;
            left: 50%; right: 0;

            z-index: -1;
        }
        &.split-bg-primary:after {
            background: $brand-primary;
        }
        &.split-bg-secondary:after {
            background: $brand-secondary;
        }
        &.split-bg-tertiary:after {
            background: $brand-tertiary;
        }
        &.split-bg-quaternary:after {
            background: $brand-quaternary;
        }
    }
}

/*
 * Profile
 */

.profile {

    margin-bottom: rhythm(1);

    p {
        margin-bottom: rhythm(0.25);
    }


    @include grid-media-query("tablet-down") {
        margin-bottom: rhythm(1);
    }

    &__image {
        > img {
            min-width: 100%;
        }
    }

    &__body {
        padding: rhythm(0.75);
    }
}