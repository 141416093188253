/* ==========================================================================
   #LAYOUT HELPERS
   ========================================================================== */

/**
 * Set element to max width and centered
 */

.contented {
    margin: 0 auto;
    max-width: $page-max;

    @media screen and (max-width: ($page-max + ($base-gutter*2))) {
        max-width: 100%;
        margin: 0 rhythm(1);
    }
}


/**
 * Set element to block
 */

.block {
    display: block;
}

@include grid-media-query("mobile") {
    .block--mobile {
        display: block;
    }
}

@include grid-media-query("tablet") {
    .block--tablet {
        display: block;
    }
}

@include grid-media-query("desk") {
    .block--desk {
        display: block;
    }
}


/*
 * Hide elements
 */

.hidden {
    display: none !important;
}

@include grid-media-query("mobile") {
    .hidden--mobile {
        display: none !important;
    }
}

@include grid-media-query("tablet") {
    .hidden--tablet {
        display: none !important;
    }
}

@include grid-media-query("desk") {
    .hidden--desk {
        display: none !important;
    }
}


/**
 * Vertically align element in middle
 */

.vertical-middle {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}