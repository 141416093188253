/* ==========================================================================
   #FOOTER
   ========================================================================== */

.site-foot{
    padding: rhythm(1) 0;

    background: $bg-alt;
    border-top: 10px solid $brand-quaternary;

    color: $brand-primary;

    @include typecsset-font-size( 14px);
    line-height: 19px;

    p {
        margin-bottom: rhythm(0.5);
    }
}

.site-foot__logo {
    @include grid-media-query("tablet-down") {
        width: 200px;
        height: 40px;
        margin-bottom: rhythm(1);
    }
}

.foot-nav {
    @include grid-media-query("mobile") {
        margin-bottom: rhythm(1);
    }
}

    .foot-nav__menu {
        margin: 0;
        list-style: none;

        > li {
            margin-bottom: rhythm(0.1);

            > a {
                margin: 0;


                &:hover {
                    color: $brand-primary;
                }
            }

            &.first > a {
                margin-left: 0;
            }
        }
    }