/* ==========================================================================
#HEADINGS
========================================================================== */

.h1,
h1 {
    @include typecsset-font-size( 23px);
    font-weight: 700;
}
    h1 {
        margin-bottom: rhythm(0.75);
    }

.h2,
h2 {
    @include typecsset-font-size( 23px);
    font-weight: 700;
}
    h2 {
        margin-bottom: rhythm(0.75);
    }

.h3,
h3 {
    @include typecsset-font-size( 17px );
    font-weight: 700;
}
    h3 {
        margin-bottom: rhythm(0.5);
    }

/* ==========================================================================
#PARAGRAPHS
========================================================================== */

p {
    margin-bottom: rhythm(1);
}

    .lead {
        @include typecsset-font-size( 28px );
        line-height: 38px;

        @include grid-media-query("mobile") {
            @include typecsset-font-size( 20px );
            line-height: 30px;
        }
    }



/* ==========================================================================
#Links
========================================================================== */

a {
    color: $brand-primary;
    text-decoration: none;

    transition: color .2s linear;
}

    a:hover {
        color: $text-color;
        text-decoration: none;
    }

a[href^=tel]{
    color: inherit;
    text-decoration: none;

    &:hover {
        color: inherit;
    }
}

a.link-email,
a.link-phone {
    font-weight: 600;
    &:before {
        margin-right: 10px;
        font-weight: 400;
    }
}