/* ==========================================================================
   #BUTTONS
   ========================================================================== */

.btn {
    //@include typecsset-font-size( 14px );
    display: inline-block;
    padding: rhythm( 0.5 ) rhythm( 1.5 );

    text-decoration: none;
    font-weight: bold;

    transition: all 0.07s linear;

    cursor: pointer;

    &:hover {
        transition: all 0.07s linear;
    }
}

    .btn--primary {
        background: $brand-primary;
        color: $text-invert;

        &:hover {
            color: $text-invert;
            background: darken($brand-primary, 10%);
        }
    }

    .btn--secondary {
        background: $brand-secondary;
        color: $text-invert;

        &:hover {
            color: $text-invert;
            background: darken($brand-secondary, 10%);
        }
    }


/*
 * Browser Fixes
 */

input[type="submit"].btn {
    -webkit-appearance: none; // stop webkit buttons overriding
}