/* ==========================================================================
   #ELEMENTS
   ========================================================================== */

* {
    box-sizing: border-box;
}

html {
    font-family: $base-font-family;
    color: $text-color;
    -webkit-font-smoothing: antialiased;
}


body {
    margin: 0;
    padding: 0;
    background: $bg-default url('../images/site-bg.jpg') no-repeat;
    background-size: cover;

    //letter-spacing: 0.025rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include typecsset-font-size( $base-font-size );
    margin: 0;
    font-weight: normal;
}

p {
    margin: 0;
}

ul,
ol,
dl {
    margin: 0;
    padding: 0;
}

dt,
dd {
    margin: 0;
}

table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

th,
td {
    padding: 0;
    text-align: left;
}

blockquote {
    margin: 0;
    font-style: italic;
}

address {
    margin: 0;
    font-style: normal;
}

figure {
    margin: 0;
}

img,
svg {
    max-width: 100%;
    vertical-align: bottom;
    height: auto;
}

audio {
    display: block;
}

video {
    display: block;
}

fieldset {
    margin: 0;
    border: 0;
    padding: 0;
}

legend {
    padding: 0;
}

input,
button,
textarea {
    font-family: $base-font-family;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    line-height: 1;
    color: $brand-quaternary;
    @include typecsset-font-size( 15px );
}

textarea {
    vertical-align: text-top;
    resize: vertical;
}

    ::-webkit-input-placeholder {
       color: $brand-quaternary;
    }

    :-moz-placeholder { /* Firefox 18- */
       color: $brand-quaternary;
    }

    ::-moz-placeholder {  /* Firefox 19+ */
       color: $brand-quaternary;
    }

    :-ms-input-placeholder {
       color: $brand-quaternary;
    }

input[type="image"] {
    border: 0;
}

// input[type="date"],
// input[type="month"],
// input[type="week"],
// input[type="datetime"],
// input[type="datetime-local"] {

// }

input[type="checkbox"],
input[type="radio"] {
    vertical-align: baseline;
}

// input[type="submit"],
// input[type="reset"],
// input[type="button"],
// button[type="submit"],
// button[type="reset"],
// button[type="button"] {

// }

// select {
// }

:focus,
:active {
    outline: none;
}

pre {
    margin: 0;
}

code,
samp,
kbd {
    line-height: 1;
}

small {
    @include typecsset-font-size( $base-font-size*0.75 );
    vertical-align: bottom;
}

sup,
sub {
    @include typecsset-font-size( ( $base-font-size*0.75 ), 0.5 );
}

strong {
    font-weight: 700;
}
