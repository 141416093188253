/* ==========================================================================
   #BURGER
   ========================================================================== */

$burgerWidth:   35px;
$burgerBar:     3px;
$burgerGap:     6px;
$burgerBg:      $brand-primary;


.burger {
    width: $burgerWidth;
    height: ($burgerBar*2) + ($burgerGap * 2);
}

    .burger__filling,
    .burger__filling:before,
    .burger__filling:after {
        display: block;
        width: 100%;
        height: $burgerBar;
        background: $burgerBg;
        transition: all 0.1s;
        backface-visibility: hidden;
    }

        .burger__filling {
            position: relative;
            transition: all 0.2s;
            transition-delay: 0.1s;
            margin: $burgerBar+$burgerGap 0 0;
        }

        .burger__filling:before,
        .burger__filling:after {
            position: absolute;
            content: "";

            transition-delay: 0s;
        }

        .burger__filling:before {
            top: ($burgerBar + $burgerGap) * -1;
        }

        .burger__filling:after {
            top: $burgerBar + $burgerGap;
        }


    // open burger
    .burger--open {

        .burger__filling {
            transform: rotate(-90deg);
            background: rgba(255,255,255,0);
            transition-delay: 0s;

            &:before {
                transition-delay: 0.15s;
                transform: translate(0px, ($burgerBar + $burgerGap)) rotate(-45deg);
            }
            &:after {
                transition-delay: 0.15s;
               transform: translate(0px, -($burgerBar + $burgerGap)) rotate(45deg);
            }
        }
    }
