/* ==========================================================================
   #MIXINS
   ========================================================================== */

/*
 * Mixin for clearfix
 * @include clearfix;
*/
@mixin clearfix {
    &:before,
    &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}


/*
 * Mixin for rhythm
 * @desc gives vertical rhythm values based on base gutter
 * @include rhythm;
*/
@function rhythm($lines: 1) {
    @return floor( $lines * $base-gutter );
}


/*
 * Mixin for hiding text
 * @desc hides text from element
 * @include hidetext;
*/
@mixin hidetext{
    overflow: hidden;
    text-indent: -9000px;
    display: block;
}

/*
 * @font-face mixin
 * Bulletproof font-face via Font Squirrel
 * @include fontface('family', 'assets/fonts/', 'myfontname');
 */

 @mixin fontface($font-family, $font-url, $font-name, $font-weight: 'normal') {
    @font-face {
        font: {
            family: $font-family;
            style: normal;
            weight: $font-weight;
        }
        src: url($font-url + '/' + $font-name + '.eot');
        src: url($font-url + '/' + $font-name + '.eot#iefix') format('embedded-opentype'),
        url($font-url + '/' + $font-name + '.woff') format('woff'),
        url($font-url + '/' + $font-name + '.ttf')  format('truetype'),
        url($font-url + '/' + $font-name + '.svg#' + $font-name) format('svg');
    }
}