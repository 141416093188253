/* ==========================================================================
   #WRAPPERS
   ========================================================================== */

.site-wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    background: #fff;
    max-width: 1200px;
    margin: 0 auto;
}

.page-main {
    margin-top: rhythm(1);

    @include grid-media-query("mobile") {
        margin-top: rhythm(3.5);
    }

    @include grid-media-query("tablet") {
        margin-top: rhythm(4);
    }

    flex: 1;

    .no-flexboxlegacy & {
        flex: none;
    }
}

.page-content {}

    .page-content--sidebar {}

.page-sidebar {}