/* ==========================================================================
   #MENUS
   ========================================================================== */

.menu {
    list-style: none;

    > li {
        display: inline-block;
    }
}